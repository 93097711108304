/**
 * Header nav responsive carousel trigger and behavior
 * Trigger slick, respond to opening/closing of top expand-o nav container
 */
(function($) {
  Drupal.behaviors.carousel_responsive_nav = {
    attach: function(context, settings) {
      const $carousel = $('.responsive-carousel-nav', context);
      const $flexSlider = $('.flexslider', context);

      // Quick function to center a variable slide carousel
      // alternate styles are applied
      // resize triggered
      function centerCarousel(slider) {
        if (slider.pagingCount == 1) {
          slider.addClass('js-flex-centered');
          slider.trigger('resize');
        }
      }

      if ($carousel.length || $flexSlider.length) {
        // Trigger flexslider on *just* our top nav carousel(s)
        $($flexSlider, $carousel).flexslider({
          animation: 'slide',
          minItems: 1,
          maxItems: 5,
          controlNav: false,
          move: 5,
          slideshow: false,
          animationLoop: true,
          itemWidth: 173,
          itemMargin: 0,
          start: centerCarousel
        });
      }

      // Respond to opening nav
      $(document).on('navOpen', function (e, category) {
        $carousel.removeClass('responsive-carousel-nav--visible');

        if (e && category) {
          $(`.responsive-carousel-nav[data-menucat="${category}"]`).addClass('responsive-carousel-nav--visible');
        }
      });

      // Respond to closing nav
      $(document).on('navClose', function () {
        $carousel.removeClass('responsive-carousel-nav--visible');
      });
    }
  };
})(jQuery);
